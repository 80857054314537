import styled from 'styled-components'
import { Content } from 'carbon-components-react'

export const StyledContent = styled(Content)`
  
`

export const Header = styled.header`
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`